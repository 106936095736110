import React, {useEffect, useReducer, useState} from "react";
import './App.css';
import {
    useNavigate
} from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import {Divider, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import List from "@mui/material/List";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import {addDays, translateRoutine} from "./helpers";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function CoachCardList(props) {
    const [list, setList] = useState([])
    const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const navigate = useNavigate();

    useEffect(() => {
        fetch(`/api/cards/list/user`, {
            credentials: "include"
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else if (res.status === 401) {
                    navigate(`/login`, {replace: true})
                } else {
                    throw res.json()
                }
            })
            .then((response) => {
                if (response) {
                    response.data.sort((a, b) => a.rowid > b.rowid)
                    setList(response.data)
                }
            })
            .catch(async (err) => {
                let error = await err
                props.showAlert("error", error.message)
            })
    }, [ignored])

    const downloadCardFile = (item) => {
        fetch(`/api/cards/${item.competition}/${item.routine}/${item.teamName}`, {
            credentials: "include"
        })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    return res.arrayBuffer()
                } else if (res.status === 401) {
                    navigate(`/login`, {replace: true})
                } else {
                    throw res.json()
                }
            })
            .then((response) => {
                fetch(`/api/cards/filename/${item.competition}/${item.routine}/${item.teamName}`, {
                    credentials: "include"
                })
                    .then(res => {
                        console.log(res);
                        if (res.status === 200) {
                            return res.json()
                        } else if (res.status === 401) {
                            navigate(`/login`, {replace: true})
                        } else {
                            throw res.json()
                        }
                    }).then(res => {
                    let blob = new Blob([response])
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = res.name;
                    link.click();

                })
            })
            .catch(async (err) => {
                let error = await err
                props.showAlert("error", error.message)
            })
    }

    const deleteCardFile = (item) => {
        setDeleteButtonLoading(true);
        fetch(`/api/cards/${item.competition}/${item.routine}/${item.teamName}`, {
            method: "DELETE",
            credentials: "include"
        })
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    props.showAlert('success', 'Карточка тренера успешно удалена')
                    console.log('удолено')
                    forceUpdate()
                } else if (res.status === 401) {
                    navigate(`/login`, {replace: true})
                } else {
                    throw res.json();
                }
            })
            .catch(async (err) => {
                let error = await err
                props.showAlert("error", error.message)
            })
            .finally(() => {
                setDeleteButtonLoading(false);
            })
    }

    return (
        <List>
            {list.map(item => {
                const isCompetitionOver = addDays(new Date(item.deadline), 7).getTime() > Date.now();
                const isAlreadyDeadline = (new Date(item.deadline)).getTime() < Date.now();
                const couldDelete = !isCompetitionOver && !isAlreadyDeadline;

                return (
                    <React.Fragment key={`${item.competition}-${item.routine}-${item.teamName}-fragment`}>
                        <ListItem key={`${item.competition}-${item.routine}-${item.teamName}`}>
                            <ListItemIcon>
                                <ContactPageIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        component="div"
                                        variant="body1"
                                        color="text.primary"
                                        sx={{fontWeight: 'bold'}}
                                    >
                                        {item.routine.split('-').map(translateRoutine).join(' ')} &bull; <em>{item.teamName}</em>
                                    </Typography>
                                }
                                secondary={
                                    <Typography component={'div'}>
                                        <Box component={'div'} sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                        >
                                            <Typography
                                                component="div"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {item.name}
                                            </Typography>
                                        </Box>
                                    </Typography>
                                }
                            />
                            <IconButton
                                onClick={() => downloadCardFile(item)}
                            >
                                <DownloadIcon color='primary'/>
                            </IconButton>
                            <LoadingButton
                                loading={deleteButtonLoading}
                                onClick={() => deleteCardFile(item)}
                                disabled={couldDelete}
                                color={'error'}
                            >
                                <DeleteForeverIcon />
                            </LoadingButton>
                        </ListItem>
                        <Divider key={`${item.competition}-${item.routine}-${item.teamName}-divider`} variant={"fullWidth"} component="li"/>
                    </React.Fragment>
                )
            })}
        </List>
    );
}


export default CoachCardList;
