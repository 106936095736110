function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookie(name) {
    setCookie(name, "", {
        'max-age': -1
    })
}

function setCookie(name, value, options = {}) {

    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

const translateRoutine = (englishName)=>{
    const translation = {
        solo: 'Соло',
        duet: 'Дуэт',
        mixed: 'См. дуэт',
        team: 'Группа',
        highlight: 'Акр. группа',
        combi: 'Комби',
        free: 'Пр.',
        tech: 'Тех.'
    }
    return translation[englishName] || 'Ошибка перевода';
}

const addDays = (theDate, days) => {
    return new Date(theDate.getTime() + days*24*60*60*1000);
}

export {getCookie, setCookie, deleteCookie, translateRoutine, addDays};
